<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Manage Transport
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card outlined>
            <grade-section-filter></grade-section-filter>
          </v-card>
          <v-container
            v-if="
              doesHaveOldbatch === true &&
              studentWithTransporations.length < 1 &&
              grade.section.selected &&
              batch.is_current_batch &&
              !transportForm.loading
            "
          >
            <alert-message type="error" title="No Transport has been set">
              <template v-slot:message>
                No transport has been defined for the selected grade & section.
                Would you like to migrate the transport from <br />
                old batch?

                <a href="" @click.prevent="fetchTransportFromOldBatch"
                  >Click Here</a
                >
              </template>
            </alert-message>
            <br />
          </v-container>

          <v-data-table
            :headers="headers"
            :hide-default-footer="transportForm.items.data.length < 1"
            :items="transportForm.items.data"
            :footer-props="footerProps"
            sort-by="roll"
            :options.sync="pagination"
            :server-items-length="transportForm.items.meta.total"
            :loading="transportForm.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + transportForm.items.meta.from }}</td>
                <td class="text-xs-left">{{ item.name }} <br> 
                  <span style="font-size: 10px; color: #666"
                      >(Enroll Code: {{ item.enroll_code }})
                      </span
                    ></td>
                <td class="text-xs-center">
                  <strong>{{ item.roll }}</strong>
                </td>
                <td class="text-xs-center">
                  <strong>{{item.guardian_name}}</strong>
                  <br>
                  <span style="font-size: 10px; color: #666"
                      >(Contact: {{ item.guardian_contact }})
                </span>
                </td>

                <td class="text-xs-left">{{ item.route || "-" }}</td>
                <td class="text-xs-left">{{ item.destination || "-" }}</td>
                <td class="text-xs-left">
                  <strong> {{ item.rate ? "NRS " + item.rate : "-" }}</strong>
                  <br />
                  <small v-if="item.rate && item.discount"
                    >Discount {{ item.discount }}% i.e NRS
                    <strong>{{
                      Math.round(item.rate * (item.discount / 100))
                    }}</strong>
                  </small>
                </td>
                <td>
                  <span v-if="item.vehicles.length > 0">
                    <v-chip
                      small
                      v-for="(vehicle, index) in item.vehicles"
                      :key="index"
                      :class="index === 0 ?'success white--text mr-2' : 'warning white--text mr-2'"
                      style="margin-top: 5px;"
                      >
                      <span v-if="index ===0">
                          Primary : {{ vehicle }} 
                      </span>
  
                      <span v-else>
                        Secondary : {{ vehicle }}
                      </span>
                    </v-chip>
                  </span>
                </td>
                <td class="text-right">
                  <edit-button
                    icon="directions_bus"
                    permission="fare-create"
                    @agree="saveOrEdit(item)"
                  />
                  <delete-button
                    v-if="
                      item.destination &&
                      item.fare_id &&
                      item.rate &&
                      item.route
                    "
                    permission="fare-delete"
                    @agree="transportForm.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>

      <v-dialog v-model="transportForm.dialog" persistent max-width="400px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>

          <v-card-text class="pb-1">
            <v-form
              @submit.prevent="save"
              ref="transportForm"
              @keydown.native="transportForm.errors.clear($event.target.name)"
              @keyup.enter="save"
              lazy-validation
            >
              <br />
              <v-layout row wrap>
                <!--<v-flex xs12 sm12>-->
                <!--</v-flex>-->

                <v-flex xs12>
                  <v-select
                    outlined
                    dense
                    :items="routes"
                    label="Route*"
                    required
                    class="pa-0 pl-2"
                    v-model="transportForm.route_id"
                    name="route_name"
                    :error-messages="transportForm.errors.get('route_id')"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-select
                    outlined
                    dense
                    :disabled="destinations.length < 1"
                    :items="destinations"
                    label="Destination*"
                    required
                    class="pa-0 pl-2 pt-2"
                    v-model="transportForm.fare_id"
                    name="route_name"
                    :error-messages="transportForm.errors.get('fare_id')"
                  />
                  <span class="rate" v-if="transportForm.fare_id">{{
                    fare ? fare.currency() : ""
                  }}</span>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    outlined
                    dense
                    autocomplete="off"
                    type="number"
                    label="Fare Discount (in %)"
                    required
                    class="pa-0 pl-2 pt-2"
                    v-model="transportForm.discount"
                    name="fare_amount"
                    :error-messages="transportForm.errors.get('discount')"
                    @keyup="discountPerChange()"
                  />
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              outlined
              @click="(transportForm.dialog = false), transportForm.reset()"
            >
              Close
            </v-btn>
            <v-btn color="success" outlined @click="store">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="migrateDialog" persistent max-width="800px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Transport Record From {{ oldBatch.name }}</span>
            <v-spacer></v-spacer>
            <a href="" @click.prevent="migrateDialog = false"
              ><v-icon>close</v-icon></a
            >
          </v-card-title>

          <v-card-text class="pb-1">
            <v-flex>
              <br />
              <v-text-field
                disabled
                outlined
                dense
                autocomplete="off"
                label="Migrate From Batch"
                required
                :value="oldBatch.name"
                name="fare_amount"
                :error-messages="transportForm.errors.get('discount')"
              />
              <v-grade-field
                @selectedGrade="selectedFromGrade"
                :inside="true"
                label="Migrate From Grade"
                :batch-id="oldBatch.id"
              />
            </v-flex>
            <br />
            <div style="display: flex">
              <!--v-if="form.items.data.length"-->
              <div class="data-represent" style="margin-top: 8px">
                <div>
                  <span class="d-success"></span> &nbsp;
                  <small> {{ oldTrasportList.length }} Total Students</small>
                </div>
                <div>
                  <span class="d-warning"></span> &nbsp;
                  <small> 10 Differente Routes</small>
                </div>

                <div>
                  <span class="d-primary"></span> &nbsp;
                  <small
                    ><strong>{{ 0 }}</strong> Total Amout</small
                  >
                </div>
              </div>
            </div>

            <v-data-table
              :headers="oldRecordHeader"
              :hide-default-footer="true"
              :items="oldTrasportList"
              :footer-props="footerProps"
              sort-by="roll"
              :options.sync="pagination"
              :server-items-length="transportForm.items.meta.total"
              :loading="transportForm.loading"
            >
              <template v-slot:item="{ index, item }">
                <tr>
                  <td>
                    {{ item.name }}
                  </td>
                  <td class="text-xs-left">{{ item.route || "-" }}</td>
                  <td class="text-xs-left">{{ item.destination || "-" }}</td>
                  <td class="text-xs-left">
                    <strong> {{ item.rate ? "NRS " + item.rate : "-" }}</strong>
                    <br />
                    <small v-if="item.rate && item.discount"
                      >Discount {{ item.discount }}% i.e NRS
                      <strong>{{
                        Math.round(item.rate * (item.discount / 100))
                      }}</strong>
                    </small>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="success"
              block
              large
              outlined
              @click="migrateTransporation"
              >Migrate to {{ batch.name }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Form from "@/library/Form";
import GradeSectionFilter from "@/components/app/GradeSectionFilter";
import Mixins from "@/library/Mixins";
import { hasOldBatch } from "@/library";

export default {
  mixins: [Mixins],
  components: {
    GradeSectionFilter,
  },
  data: () => ({
    btns: [["Removed", "0"]],
    colors: ["deep-purple accent-4", "error", "teal darken-1"],
    items: [...Array(4)].map((_, i) => `Item ${i}`),
    downloadColumns: [
      { value: "roll", name: "Roll No.", selected: true },
      { value: "name", name: "Student Name", selected: true },
      { value: "route", name: "Route", selected: true },
      { value: "destination", name: "Destination", selected: true },
      { value: "driver", name: "Driver Name", selected: false },
      { value: "vehicle", name: "Vehicle Name", selected: false },
      { value: "vehicle_no", name: "Vehicle No.", selected: false },
      { value: "rate", name: "Rate", selected: true },
      { value: "discount", name: "Discount", selected: true },
    ],
    migrateDialog: false,

    transportForm: new Form(
      {
        route_id: "",
        enroll_id: "",
        discount: "",
        fare_id: "",
      },
      "/api/transport"
    ),
    routes: [],
    fare: "",
    url: "",
    destinations: [],
    oldBatch: {},
    headers: [
      { text: "#", align: "left", value: "sno", sortable: false, width: 20 },
      {
        text: "Name",
        align: "left",
        value: "name",
        sortable: false,
        width: 180,
      },
      {
        text: "R. No.",
        align: "left",
        value: "roll",
        sortable: false,
        width: 70,
      },
      
      {
        text: "Guardian",
        align: "left",
        value: "guardian",
        sortable: false,
        width: 70,
      },

      { text: "Route", align: "left", value: "type", sortable: false },
      { text: "Destination", align: "left", value: "seat", sortable: false },
      {
        text: "Fee",
        align: "left",
        value: "driver",
        sortable: false,
      },
      { 
        value: "Vehicles", 
        text: "vehicles",   
        align: "left",
        sortable: false, },

      { text: "Action", align: "right", sortable: false, width: 200 },
    ],
    oldRecordHeader: [
      {
        text: "Name",
        align: "left",
        value: "name",
        sortable: false,
        width: 180,
      },
      { text: "Route", align: "left", value: "type", sortable: false },
      { text: "Destination", align: "left", value: "seat", sortable: false },
      {
        text: "Fee",
        align: "left",
        value: "driver",
        sortable: false,
        width: 200,
      },
    ],
    oldTrasportList: [],
    pagination: {
      itemsPerPage: 10,
    },
    rowsPerPageItems: [5, 10, 25, 50, 75],
    types: [
      { text: "Bus", value: "bus" },
      { text: "Van", value: "van" },
      { text: "Micro", value: "micro" },
    ],
    drivers: [],
    search: "",
    studentWithTransporations: [],
  }),
  watch: {
    "grade.section.selected": function (value) {
      this.get();
    },
    pagination: function () {
      this.get();
    },
    "transportForm.route_id": function () {
      this.getDestinations();
    },
    "transportForm.fare_id": function (v) {
      let fareData = [];
      fareData = this.destinations.filter((des) => {
        if (des.value == v) {
          return des;
        }
      });
      if (fareData.length > 0) this.fare = fareData[0].rate;
    },
  },
  computed: {
    ...mapState(["grade", "batch", "batches"]),
    selectedGrade() {
      let output = {};
      this.grade.grade.items.map((item) => {
        if (item.id === this.grade.grade.selected) {
          output = item;
        }
      });
      return output;
    },
    doesHaveOldbatch() {
      return hasOldBatch(this.batches);
    },
  },
  mounted() {},
  methods: {
    selectedFromGrade(grade) {
      this.$rest
        .get(
          `/api/transport?gradeId=${grade.id}&sectionId=&rowsPerPage=1000&batchId=${this.oldBatch.id}&forMigration=true`
        )
        .then((res) => {
          if (res.data.length) {
            this.oldTrasportList = res.data;
          }
        });
    },
    get(fetchForOldBatch = false) {
      let extraParams =
        "gradeId=" +
        this.grade.grade.selected +
        "&sectionId=" +
        this.grade.section.selected;
      if (fetchForOldBatch) {
        extraParams += `&fetchForOldBatch=${fetchForOldBatch}`;
      }
      let query = this.queryString(extraParams);

      if (this.grade.section.selected) {
        this.transportForm.get(null, query).then(({ data }) => {
          this.url = data.url;
          this.studentWithTransporations = [];
          data.data.map((item) => {
            if (item.destination) {
              this.studentWithTransporations.push(item);
            }
          });
        });
      }
    },
    store() {
      this.transportForm.store();
    },
    migrateTransporation() {
      this.$rest
        .post("/api/transport/migrate", {
          grade_id: this.grade.grade.selected,
          batch_id: this.batch.id,
          transportations: this.oldTrasportList,
        })
        .then((res) => {
          this.migrateDialog = false;
          this.get();
          this.$events.fire("notification", {
            message: res.data.message,
            status: "success",
          });
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        });
    },
    fetchTransportFromOldBatch() {
      let finalBatches = this.batches.filter((item) => {
        return item.is_hs === this.batch.is_hs && item.id !== this.batch.id;
      });
      let oldBatch = finalBatches[finalBatches.length - 1];

      this.batches.map((item) => {
        if (this.batch.is_hs == true && item.is_hs === true) {
        }
      });

      if (oldBatch && !oldBatch.is_current_batch) {
        this.oldTrasportList = [];
        // this.migrateDialog = false;
        this.oldBatch = oldBatch;
        this.migrateDialog = true;
      }
    },
    getDrivers() {
      this.$rest.get("/api/member?type=driver").then(({ data }) => {
        this.drivers = data.data.map((driver) => {
          return { text: driver.name, value: driver.id };
        });
      });
    },
    getRoutes() {
      this.$rest.get("/api/route?rowsPerPage=100").then(({ data }) => {
        let $this = this;
        this.routes = data.data.map((route) => {
          return { text: route.name, value: route.id };
        });
      });
    },
    getDestinations() {
      this.$rest
        .get(
          "/api/route-fare?rowsPerPage=25&routeId=" +
            this.transportForm.route_id
        )
        .then((res) => {
          this.destinations = res.data.map(function (des) {
            return {
              text: des.destination,
              value: des.fare_id,
              rate: des.rate,
            };
          });
        });
    },

    discountPerChange() {
      if (this.transportForm.discount > 100) this.transportForm.discount = 100;
    },
    saveOrEdit(student) {
      this.transportForm.dialog = true;
      this.getRoutes();
      //build
      this.transportForm.enroll_id = student.enroll_id;
      if (
        student.destination &&
        student.fare_id &&
        student.rate &&
        student.route
      ) {
        this.transportForm.edit(student);
      }
    },
    download(type) {
      let columns = [];
      this.downloadColumns.map((item) => {
        if (item.selected === true) columns.push(item.value);
      });
      let section = {};
      this.grade.section.items.map((item) => {
        if (item.id === this.grade.section.selected) {
          section = item;
        }
      });
      if (columns.length > 0)
        window.open(this.url + `&type=${type}&column=${columns.join(",")}`);
    },
  },
};
</script>
<style>
.rate {
  position: absolute;
  margin-top: -20px;
  margin-left: 10px;
  color: gray;
  font-size: 12px;
}
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 14px !important;
}
.v-menu__content .v-input--selection-controls {
  margin-top: 0 !important;
}
</style>
